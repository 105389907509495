import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Nullable, FileObj } from '~globals/types';
import { RouteItemStateType } from '~globals/types/enums';
import { uploadImages } from '~services/files';
import {
  setFeedbackRoutItem,
  validateCollectItemsOnRoutItem,
} from '~services/route';
import {
  RouteItemFeedbackSetItemTasks,
  RouteItemFeedbackSetBody,
  RouteItemFeedback,
} from '~services/route/types';
import { ItemCollect } from '~services/item/types';

export interface SetRoutItemFeedbackData {
  routeItemId: string;
  routeItemStateTypeId: RouteItemStateType;
  arrivalDate: Nullable<string>;
  deliveredDate: string;
  amountPaid: Nullable<number>;
  docsFiles: FileObj[];
  evidencesFiles: FileObj[];
  receptorName: Nullable<string>;
  receptorDocument: Nullable<string>;
  notes: Nullable<string>;
  rejectedReason: Nullable<string>;
  itemTasks: RouteItemFeedbackSetItemTasks[];
  itemCollects: ItemCollect[];
  isCollect: boolean;
  isModify: boolean;
}

export interface SetRoutItemFeedbackParams {
  onSuccess?: (currentRouteItemFeedback: RouteItemFeedback) => void;
  onError?: () => void;
}

export interface SetRoutItemFeedbackReturn {
  loading: boolean;
  handleSetRoutItemFeedback: (
    feedbackData: SetRoutItemFeedbackData,
  ) => Promise<void>;
}

export const useSetRoutItemFeedback = (
  options?: SetRoutItemFeedbackParams,
): SetRoutItemFeedbackReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const executeUploadImages = useCallback(async (files: FileObj[]) => {
    const dataUploadFile = await uploadImages(files);

    if (dataUploadFile.ok && dataUploadFile.data) {
      const filesIds = dataUploadFile.data.data.results.map(
        (file) => file.fileId,
      );

      return filesIds;
    }

    return Promise.reject('Error uploading files');
  }, []);

  const executeSetEvidence = useCallback(
    async (data: RouteItemFeedbackSetBody) => {
      const dataEvidence = await setFeedbackRoutItem(data);

      if (dataEvidence.ok && dataEvidence.data) {
        return dataEvidence.data.data;
      }

      return Promise.reject('Error set evidence');
    },
    [],
  );

  const executeValidateItemCollects = useCallback(
    async (itemCollects: ItemCollect[]) => {
      const validateItemCollectsResponse = await validateCollectItemsOnRoutItem(
        { itemCollects },
      );

      if (
        validateItemCollectsResponse.ok &&
        validateItemCollectsResponse.data
      ) {
        return validateItemCollectsResponse.data.data;
      }

      return Promise.reject('Error validate item collects');
    },
    [],
  );

  const handleSetRoutItemFeedback = useCallback(
    async (feedbackData: SetRoutItemFeedbackData) => {
      setLoading(true);

      try {
        const docFiles = feedbackData.docsFiles;
        let docFilesIds: string[] = [];

        if (docFiles.length > 0) {
          docFilesIds = await executeUploadImages(docFiles);
        }

        const evidencesFiles = feedbackData.evidencesFiles;
        let evidencesFilesIds: string[] = [];

        if (evidencesFiles.length > 0) {
          evidencesFilesIds = await executeUploadImages(evidencesFiles);
        }

        const routeItemId = feedbackData.routeItemId;
        const routeItemStateTypeId = feedbackData.routeItemStateTypeId;

        const currentRouteItemFeedback = await executeSetEvidence({
          routeItemId,
          routeItemStateTypeId,
          routeItemFeedback: {
            routeItemId,
            routeItemStateTypeId,
            arrivalTime: feedbackData.arrivalDate,
            deliveredTime: feedbackData.deliveredDate,
            amountPaid: feedbackData.amountPaid,
            routeItemFeedbackFiles: evidencesFilesIds,
            routeItemFeedbackDocs: docFilesIds,
            receptorName: feedbackData.receptorName,
            receptorId: feedbackData.receptorDocument,
            notes: feedbackData.notes,
            rejectedReason: feedbackData.rejectedReason,
            itemTasks: feedbackData.itemTasks,
          },
        });

        if (feedbackData.isCollect && feedbackData.itemCollects.length > 0) {
          await executeValidateItemCollects(feedbackData.itemCollects);
        }

        options?.onSuccess?.(currentRouteItemFeedback);

        const successMessage = feedbackData.isModify
          ? 'Evidencia modificada correctamente'
          : 'Parada finalizada correctamente';

        enqueueSnackbar(successMessage, { variant: 'success' });
      } catch (_err) {
        options?.onError?.();

        const errorMessage = feedbackData.isModify
          ? 'No se pudo modificar la evidencia, intente nuevamente'
          : 'No se pudo finalizar la parada, intente nuevamente';

        enqueueSnackbar(errorMessage, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      executeUploadImages,
      executeSetEvidence,
      executeValidateItemCollects,
      options,
    ],
  );

  return { loading, handleSetRoutItemFeedback };
};
