import { AvailableRoutesStateComponents } from './AvailableRoutesState';
import SubscriptionBillinDataInfo from './BillingDataInfo';
import SubscriptionCustomPlanInfoCard from './CustomPlanInfoCard';
import { DataRetentionMinComponents } from './DataRetentionMin';
import SubscriptionEditPaymentModal from './EditPaymentModal';
import SubscriptionInfoMercadoPagoCard from './InfoMercadoPagoCard';
import SubscriptionPackagePriceCard from './PackagePriceCard';
import SubscriptionPaymentModal from './PaymentModal';
import SubscriptionPaymentTypeSelector from './PaymentTypeSelector';
import SubscriptionPlanCard from './PlanCard';
import SubscriptionPlanInfoChip from './PlanInfoChip';
import SubscriptionPlanPriceCard from './PlanPriceCard';
import SubscriptionTransactionStatusChip from './TransactionStatusChip';

const SubscriptionComponents = {
  AvailableRoutesState: AvailableRoutesStateComponents,
  BillingDataInfo: SubscriptionBillinDataInfo,
  CustomPlanInfoCard: SubscriptionCustomPlanInfoCard,
  DataRetentionMin: DataRetentionMinComponents,
  EditPaymentModal: SubscriptionEditPaymentModal,
  InfoMercadoPagoCard: SubscriptionInfoMercadoPagoCard,
  PackagePriceCard: SubscriptionPackagePriceCard,
  PaymentModal: SubscriptionPaymentModal,
  PaymentTypeSelector: SubscriptionPaymentTypeSelector,
  PlanCard: SubscriptionPlanCard,
  PlanInfoChip: SubscriptionPlanInfoChip,
  PlanPriceCard: SubscriptionPlanPriceCard,
  TransactionStatusChip: SubscriptionTransactionStatusChip,
};

export default SubscriptionComponents;
