import { ReactElement, useMemo, useCallback, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red, yellow } from '@mui/material/colors';
import {
  faDolly as WarouseIcon,
  faGripVertical as GridVerticalIcon,
  faClock as ClockIcon,
  faCalendarXmark as CalendarXmarkIcon,
  faCubes as CubesIcon,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { Visibility as ShowIcon } from '@mui/icons-material';
import mapboxgl from 'mapbox-gl';
import { useDrag, useDrop } from 'react-dnd';

import { AccessFeature } from '~components/index';
import { RouteItemStateType, PlanFeaturesTypes } from '~globals/types/enums';
import { pluralize } from '~utils/commons';
import { isDelayedOrder } from '~utils/order';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { useToggle, useUtcOffset } from '~hooks/index';
import { Nullable } from '~globals/types';

import { RouteItemStopDndCardProps } from './types';
import { RouteItemStopStateTypeList } from '../RouteItemStopCard/types';
import MenuActionsDataTable from '../MenuActionsDataTable';
import DescriptionWithIconBox from '../DescriptionWithIconBox';
import IconWithTooltip from '../IconWithTooltip';
import {
  RouteItemStopDndCardContainer,
  RouteItemStopDndCardDivider,
  RouteItemStopDndCardIconButtonContainer,
  RouteItemStopDndCardCheckbox,
  RouteItemStopDndCardIconText,
  RouteItemStopDndCardInfoContainer,
  RouteItemStopDndCardInfoHeader,
  RouteItemStopDndCardInfoTimeContainer,
  RouteItemStopDndCardInfoTimeDescriptions,
  RouteItemStopDndCardInfoTimeDescriptionsText,
  RouteItemStopDndCardInfoPrincipalContainer,
  RouteItemStopDndTitle,
  RouteItemStopDndText,
  RouteItemStopDndIconButton,
  RouteItemStopDndCardSortableIconContainer,
  RouteItemStopDndCardInfoWarningsContainer,
  RouteItemStopDndCardInfoActionsContainer,
  RouteItemStopDndCardItemTaksWrapper,
} from './styles';

const RouteItemStopDndState: RouteItemStopStateTypeList = {
  [RouteItemStateType.New]: 'default',
  [RouteItemStateType.OnAgenda]: 'default',
  [RouteItemStateType.InProgress]: 'default',
  [RouteItemStateType.FinalizedSuccess]: 'success',
  [RouteItemStateType.FinalizedError]: 'error',
  [RouteItemStateType.WithoutVisiting]: 'warning',
};

interface ItemDnd {
  routeItemId: string;
  originalIndex: number;
}

const RouteItemStopDndCard = ({
  routeItemId,
  isWarehouse,
  order,
  routeItemStateTypeId,
  title,
  address,
  estimatedArrivalDateTime,
  serviceDuration,
  referenceId,
  actionsOptions,
  longitude,
  latitude,
  mapRef,
  acceptTypeDnd,
  findCard,
  moveCard,
  isSortable: isSortableProps,
  isSelectable: isSelectableProps,
  isSelected,
  onSelect,
  isWithinTimeWindow: isWithinTimeWindowProps,
  chargeUnit1,
  chargeUnit2,
  itemTasks,
  itemCollects,
  itemId,
  onOpenDetailsOrder,
}: RouteItemStopDndCardProps): ReactElement => {
  const { index: originalIndex } = findCard(routeItemId);

  const formatterDateUtcOffset = useUtcOffset();

  const [{ isDragging }, dragConnect] = useDrag(
    () => ({
      type: acceptTypeDnd,
      item: { routeItemId, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const {
          routeItemId: droppedRouteItemId,
          originalIndex: dropperOriginalIndex,
        } = item;

        if (!monitor.didDrop()) {
          moveCard(droppedRouteItemId, dropperOriginalIndex);
        }
      },
    }),
    [routeItemId, moveCard],
  );

  const [, dropConnect] = useDrop(
    () => ({
      accept: acceptTypeDnd,
      hover: (item) => {
        const { routeItemId: draggedRouteItemId } = item as ItemDnd;

        if (draggedRouteItemId !== routeItemId) {
          moveCard(draggedRouteItemId, originalIndex);
        }
      },
    }),
    [routeItemId, moveCard, originalIndex],
  );

  const isSortable = useMemo(
    () => isSortableProps && !isWarehouse,
    [isSortableProps, isWarehouse],
  );

  const isSelectable = useMemo(
    () => isSelectableProps && !isWarehouse,
    [isSelectableProps, isWarehouse],
  );

  const connectRef = useCallback(
    (node: Nullable<HTMLDivElement>) =>
      isSortable ? dragConnect(dropConnect(node)) : node,
    [isSortable, dragConnect, dropConnect],
  );

  const [openTooltipIcon, setOpenTooltipIcon] = useToggle();

  const Icon = useMemo(() => {
    let output = (
      <RouteItemStopDndCardIconText>{order}</RouteItemStopDndCardIconText>
    );

    if (isWarehouse) output = <FontAwesomeIcon icon={WarouseIcon} />;

    return output;
  }, [order, isWarehouse]);

  const IconSortable = useMemo(
    () => (isWarehouse && order === 0 ? WarouseIcon : GridVerticalIcon),
    [isWarehouse, order],
  );

  const goToMap = useCallback(() => {
    if (mapRef) {
      const stopLngLat = new mapboxgl.LngLat(longitude, latitude);
      mapRef.flyTo({ center: stopLngLat });

      const tripbounds = new mapboxgl.LngLatBounds();
      tripbounds.extend(stopLngLat);
      mapRef.fitBounds(tripbounds, { maxZoom: 14 });
    }
  }, [mapRef, longitude, latitude]);

  const isWithinTimeWindow = useMemo(
    () =>
      !isWithinTimeWindowProps && !isWarehouse && !!estimatedArrivalDateTime,
    [isWithinTimeWindowProps, isWarehouse, estimatedArrivalDateTime],
  );

  const isDelayed = useMemo(
    () =>
      isDelayedOrder(estimatedArrivalDateTime) &&
      !isWarehouse &&
      !!estimatedArrivalDateTime,
    [estimatedArrivalDateTime, isWarehouse],
  );

  const parseEstimatedArrivalDateTime = useMemo(() => {
    if (!estimatedArrivalDateTime) return 'Calculando';

    return formatterDateUtcOffset(estimatedArrivalDateTime, 'HH:mm');
  }, [formatterDateUtcOffset, estimatedArrivalDateTime]);

  const itemTasksCount = useMemo(() => itemTasks.length, [itemTasks]);

  const itemTaskTooltip = useMemo(() => {
    return pluralize({
      singular: 'artículo / tarea',
      plural: 'artículos / tareas',
      count: itemTasksCount,
      includeCount: true,
    });
  }, [itemTasksCount]);

  const itemCollectCount = useMemo(() => itemCollects.length, [itemCollects]);

  const itemCollectTooltip = useMemo(() => {
    return pluralize({
      singular: 'pedido',
      count: itemCollectCount,
      includeCount: true,
    });
  }, [itemCollectCount]);

  return (
    <RouteItemStopDndCardContainer
      ref={connectRef}
      isDragging={isDragging}
      enabledMove={isSortable}
    >
      <RouteItemStopDndCardDivider orientation="vertical" />

      <Tooltip
        open={openTooltipIcon}
        onClose={() => setOpenTooltipIcon(false)}
        onOpen={() => setOpenTooltipIcon(true)}
        title="Ver en mapa"
        arrow
        placement="right"
      >
        <RouteItemStopDndCardIconButtonContainer
          state={RouteItemStopDndState[routeItemStateTypeId]}
          enabledBorder
          onClick={goToMap}
        >
          {openTooltipIcon ? (
            <ShowIcon className="RouteItemStopDndCard-showIcon" />
          ) : (
            Icon
          )}
        </RouteItemStopDndCardIconButtonContainer>
      </Tooltip>

      <RouteItemStopDndCardInfoContainer>
        <RouteItemStopDndCardInfoHeader>
          <RouteItemStopDndCardInfoTimeContainer>
            <RouteItemStopDndCardInfoTimeDescriptions>
              <RouteItemStopDndCardInfoTimeDescriptionsText>
                {parseEstimatedArrivalDateTime}
              </RouteItemStopDndCardInfoTimeDescriptionsText>

              <RouteItemStopDndCardInfoTimeDescriptionsText>
                {pluralize({
                  singular: 'min',
                  count: serviceDuration / 60,
                  includeCount: true,
                })}
              </RouteItemStopDndCardInfoTimeDescriptionsText>

              {!isWarehouse && referenceId && (
                <Tooltip title={`Nro. de Orden: ${referenceId}`} arrow>
                  <RouteItemStopDndCardInfoTimeDescriptionsText
                    sx={{ maxWidth: 120 }}
                  >
                    {referenceId}
                  </RouteItemStopDndCardInfoTimeDescriptionsText>
                </Tooltip>
              )}

              {!isWarehouse && chargeUnit1 !== undefined && (
                <RouteItemStopDndCardInfoTimeDescriptionsText>
                  <DescriptionWithIconBox
                    icon={
                      <img
                        src={toAbsoluteUrl('/icons/icon_capacity_1.svg')}
                        alt="Capacity One Icon"
                        style={{ width: 13, height: 'auto' }}
                      />
                    }
                    description={chargeUnit1.toString()}
                    size={10}
                    iconTooltip="Carga primaria (En KG, L, dinero, bultos)"
                  />
                </RouteItemStopDndCardInfoTimeDescriptionsText>
              )}

              {!isWarehouse && chargeUnit2 !== undefined && (
                <RouteItemStopDndCardInfoTimeDescriptionsText>
                  <DescriptionWithIconBox
                    icon={
                      <img
                        src={toAbsoluteUrl('/icons/icon_capacity_2.svg')}
                        alt="Capacity Two Icon"
                        style={{ width: 13, height: 'auto' }}
                      />
                    }
                    description={chargeUnit2.toString()}
                    size={10}
                    iconTooltip="Carga secundaria (En KG, L, dinero, bultos)"
                  />
                </RouteItemStopDndCardInfoTimeDescriptionsText>
              )}

              {/* {!isWarehouse && itemTasksCount > 0 && (
                <RouteItemStopDndCardInfoTimeDescriptionsText>
                  <DescriptionWithIconBox
                    icon={<FontAwesomeIcon icon={CubesIcon} />}
                    description={`${itemTasksCount} Art.`}
                    size={10}
                    iconTooltip="Artículos / Tareas"
                  />
                </RouteItemStopDndCardInfoTimeDescriptionsText>
              )} */}

              {!isWarehouse && itemTasksCount > 0 && (
                <AccessFeature.Hidden
                  validFeature={PlanFeaturesTypes.MgmItemTask}
                  type="notAccess"
                >
                  <RouteItemStopDndCardInfoTimeDescriptionsText>
                    <Tooltip title={itemTaskTooltip} arrow>
                      <RouteItemStopDndIconButton
                        onClick={() => onOpenDetailsOrder?.(itemId, 'articles')}
                      >
                        <RouteItemStopDndCardItemTaksWrapper icon={CubesIcon} />
                      </RouteItemStopDndIconButton>
                    </Tooltip>
                  </RouteItemStopDndCardInfoTimeDescriptionsText>
                </AccessFeature.Hidden>
              )}

              {!isWarehouse && itemCollectCount > 0 && (
                <RouteItemStopDndCardInfoTimeDescriptionsText>
                  <Tooltip title={itemCollectTooltip} arrow>
                    <RouteItemStopDndIconButton
                      onClick={() => onOpenDetailsOrder?.(itemId, 'orders')}
                    >
                      <RouteItemStopDndCardItemTaksWrapper icon={CubesIcon} />
                    </RouteItemStopDndIconButton>
                  </Tooltip>
                </RouteItemStopDndCardInfoTimeDescriptionsText>
              )}
            </RouteItemStopDndCardInfoTimeDescriptions>
          </RouteItemStopDndCardInfoTimeContainer>

          <RouteItemStopDndCardInfoWarningsContainer>
            {isWithinTimeWindow && !isSortable && !isSelectable && (
              <IconWithTooltip
                tooltip="Fuera de ventana horaria"
                fontSize={12}
                color={yellow[600]}
                icon={<FontAwesomeIcon icon={ClockIcon} />}
              />
            )}

            {isDelayed && !isSortable && !isSelectable && (
              <IconWithTooltip
                tooltip="Este pedido está demorado"
                fontSize={12}
                color={red[600]}
                icon={<FontAwesomeIcon icon={CalendarXmarkIcon} />}
              />
            )}
          </RouteItemStopDndCardInfoWarningsContainer>

          <RouteItemStopDndCardInfoActionsContainer>
            {actionsOptions && (
              <MenuActionsDataTable
                {...actionsOptions}
                optionsButton={{
                  ...actionsOptions.optionsButton,
                  show:
                    actionsOptions.optionsButton?.show &&
                    !isSortable &&
                    !isSelectable,
                }}
              />
            )}

            {isSortable && (
              <RouteItemStopDndCardSortableIconContainer icon={IconSortable} />
            )}

            {isSelectable && (
              <RouteItemStopDndCardCheckbox
                checked={isSelected}
                onChange={onSelect}
                size="small"
                disableRipple
              />
            )}
          </RouteItemStopDndCardInfoActionsContainer>
        </RouteItemStopDndCardInfoHeader>

        <RouteItemStopDndCardInfoPrincipalContainer>
          <RouteItemStopDndTitle>{title}</RouteItemStopDndTitle>

          {address && <RouteItemStopDndText>{address}</RouteItemStopDndText>}
        </RouteItemStopDndCardInfoPrincipalContainer>
      </RouteItemStopDndCardInfoContainer>
    </RouteItemStopDndCardContainer>
  );
};

export default memo(RouteItemStopDndCard);
