import { ReactElement, useCallback, useMemo } from 'react';
import { Stack, TextField, MenuItem, Divider } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import moment from 'moment';

import { hasError } from '~utils/formHelpers';
import { RouteItemStateType } from '~globals/types/enums';
import { getItemValue, formatterDate } from '~utils/index';
import { useUtcOffset } from '~hooks/index';

import { useDialogFeedbackStopContext } from '../../DialogSetFeedbackStopContext';
import DialogSetFeedbackStopContent from '../../DialogSetFeedbackStopContent';
import DialogSetFeebackStopResetStep from '../../DialogSetFeebackStopResetStep';
import DialogSetFeedBackStopFileUpload from '../../DialogSetFeedBackStopFileUpload';

import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';

const DialogSetFeedbackStopIncompleted = (): ReactElement => {
  const {
    routeItemId,
    rejectedReasons,
    data,
    isModify,
    currentScheduledDateTime,
    setItemFeedbackRequest: { loading: loadingSetFeedback, handleSetFeedback },
  } = useDialogFeedbackStopContext();

  const formatterDateUtcOffset = useUtcOffset();

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      const routeItemStateTypeId = RouteItemStateType.FinalizedError;

      const arrivalDate = formatterDate(values[FIELDS_NAME.ARRIVAL_DATE], {
        format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
      });

      const deliveredDate = data?.deliveredDate
        ? formatterDate(data.deliveredDate, {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          })
        : arrivalDate;

      await handleSetFeedback({
        routeItemId,
        routeItemStateTypeId,
        arrivalDate,
        deliveredDate,
        amountPaid: null,
        docsFiles: [],
        evidencesFiles: values[FIELDS_NAME.EVIDENCE_FILES],
        receptorName: null,
        receptorDocument: null,
        notes: getItemValue(values, FIELDS_NAME.NOTES),
        rejectedReason: getItemValue(values, FIELDS_NAME.REJECTED_REASON),
        itemTasks: [],
        itemCollects: [],
        isCollect: false,
        isModify,
      });

      setSubmitting(false);
    },
    [data, handleSetFeedback, routeItemId, isModify],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit,
  });

  const {
    values,
    touched,
    errors,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isSubmitting,
    dirty,
  } = formikBag;

  const arrivalMinDate = useMemo(() => {
    if (currentScheduledDateTime) {
      return new Date(
        formatterDateUtcOffset(
          currentScheduledDateTime,
          moment.HTML5_FMT.DATETIME_LOCAL,
        ),
      );
    }

    return undefined;
  }, [currentScheduledDateTime, formatterDateUtcOffset]);

  return (
    <DialogSetFeedbackStopContent
      onSubmit={submitForm}
      loadingSubmit={loadingSetFeedback || isSubmitting}
      disabledSubmit={isModify && !dirty}
    >
      <FormikProvider value={formikBag}>
        <Stack spacing={2} flex={1} height="100%">
          <DialogSetFeebackStopResetStep />

          <Stack spacing={2} divider={<Divider />}>
            <DateTimePicker
              label="Fecha"
              value={values[FIELDS_NAME.ARRIVAL_DATE]}
              onChange={(dateValue) =>
                setFieldValue(FIELDS_NAME.ARRIVAL_DATE, dateValue)
              }
              minDateTime={arrivalMinDate}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  onBlur={() => setFieldTouched(FIELDS_NAME.ARRIVAL_DATE, true)}
                  error={hasError(touched, errors, FIELDS_NAME.ARRIVAL_DATE)}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            <TextField
              label="Razón de visita incompleta"
              error={hasError(touched, errors, FIELDS_NAME.REJECTED_REASON)}
              {...getFieldProps(FIELDS_NAME.REJECTED_REASON)}
              select
              fullWidth
              InputLabelProps={{ shrink: true }}
            >
              {rejectedReasons.map((rejectedReason, rejectedReasonIndex) => (
                <MenuItem
                  key={`rejected-reason-${rejectedReasonIndex}`}
                  value={rejectedReason}
                >
                  {rejectedReason}
                </MenuItem>
              ))}
            </TextField>

            <DialogSetFeedBackStopFileUpload
              name={FIELDS_NAME.EVIDENCE_FILES}
              label="Evidencia de visita (Opcional)"
              labelUplaodButton="Cargar evidencia"
              emptyMessage="No hay imagenes para mostrar"
            />

            <TextField
              label="Notas (Opcional)"
              fullWidth
              multiline
              rows={3}
              {...getFieldProps(FIELDS_NAME.NOTES)}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
      </FormikProvider>
    </DialogSetFeedbackStopContent>
  );
};

export default DialogSetFeedbackStopIncompleted;
