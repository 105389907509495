import {
  FC,
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSnackbar } from 'notistack';

import { Nullable } from '~globals/types';
import {
  ItemTypes,
  RouteItemStateType,
  RouteStateTypes,
} from '~globals/types/enums';
import { useRequest, useUpdateRouteState } from '~hooks/index';
import {
  useSetRoutItemFeedback,
  SetRoutItemFeedbackData,
} from '~hooks/useSetRoutItemFeedback';
import { AdminCompanySetting } from '~services/admin/companySetting/types';
import { getAdminCompanySetting } from '~services/admin/companySetting';
import { getItemById } from '~services/item';
import {
  ItemCreateExtended,
  ItemTask,
  ItemCollect,
} from '~services/item/types';
import { getWarehouseInfo } from '~services/warehouse';
import { WarehouseInfo } from '~services/warehouse/types';
import { getRoute, getFeedbackRouteItem } from '~services/route';
import {
  RouteItemFeedback,
  RouteItemFeedbackFile,
} from '~services/route/types';
import { ApiTransactionRoutes } from '~services/apiTransaction/types';
import { isRouteStopOrderPending } from '~utils/route';

import { DialogSetFeedbackStopProps } from './types';

export type DialogSetFeebakStopContextStepsId =
  | 'selection'
  | 'completed'
  | 'incompleted';

export interface DialogSetFeebakStopContextData {
  title: string;
  isCollect: boolean;
  arrivalDate: Nullable<string>;
  deliveredDate: Nullable<string>;
  cashOnDeliveryAmount: Nullable<number>;
  amountPaid: Nullable<number>;
  docsFiles: RouteItemFeedbackFile[];
  evidencesFiles: RouteItemFeedbackFile[];
  receptorName: Nullable<string>;
  receptorDocument: Nullable<string>;
  rejectedReason: Nullable<string>;
  itemTasks: ItemTask[];
  itemCollects: ItemCollect[];
  notes: Nullable<string>;
  isWarehouse: boolean;
  currentItemId: Nullable<string>;
}

export interface DialogSetFeebakStopContextValue
  extends DialogSetFeedbackStopProps {
  currentStep: {
    step: DialogSetFeebakStopContextStepsId;
    setCurrentStep: (newActiveStep: DialogSetFeebakStopContextStepsId) => void;
  };
  data: Nullable<DialogSetFeebakStopContextData>;
  rejectedReasons: string[];
  currentScheduledDateTime: Nullable<string>;
  totalPendingRoutesItems: number;
  loading: boolean;
  isModify: boolean;
  setItemFeedbackRequest: {
    loading: boolean;
    handleSetFeedback: (feedback: SetRoutItemFeedbackData) => Promise<void>;
  };
}

const DialogSetFeebakStopContext =
  createContext<DialogSetFeebakStopContextValue>({
    open: false,
    onClose: () => {},
    routeItemId: '',
    routeId: '',
    itemId: undefined,
    warehouseId: undefined,
    currentStep: {
      step: 'selection',
      setCurrentStep: () => {},
    },
    data: null,
    rejectedReasons: [],
    currentScheduledDateTime: null,
    totalPendingRoutesItems: 0,
    loading: false,
    isModify: false,
    setItemFeedbackRequest: {
      loading: false,
      handleSetFeedback: () => Promise.resolve(),
    },
  });

export const useDialogFeedbackStopContext =
  (): DialogSetFeebakStopContextValue => useContext(DialogSetFeebakStopContext);

const DialogSetFeebakStopProvider: FC<DialogSetFeedbackStopProps> = ({
  children,
  ...restProps
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentStep, setCurrentStep] =
    useState<DialogSetFeebakStopContextStepsId>('selection');

  const { itemId, warehouseId, routeItemId, routeId, onClose } = restProps;

  const hasItemId = useMemo(() => Boolean(itemId), [itemId]);
  const hasWarehouseId = useMemo(() => Boolean(warehouseId), [warehouseId]);

  const isModify = useMemo(
    () => !hasItemId && !hasWarehouseId,
    [hasItemId, hasWarehouseId],
  );

  const [rejectedReasons, setRejectedReasons] = useState<string[]>([]);

  const [, loadingPreferences, ,] = useRequest({
    request: getAdminCompanySetting,
    payload: null,
    withPostSuccess: (response) => {
      const companySettingResponse = response.data?.data as AdminCompanySetting;

      const currentRejectedReasons: string[] = JSON.parse(
        companySettingResponse.feedbackRequestRejectedReason,
      );

      setRejectedReasons(currentRejectedReasons);
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const [currentScheduledDateTime, setCurrentScheduledDateTime] =
    useState<Nullable<string>>(null);
  const [totalPendingRoutesItems, setTotalPendingRoutesItems] = useState(0);

  const [, loadingRoute] = useRequest(
    {
      request: getRoute,
      payload: routeId,
      withPostSuccess: (response) => {
        const { scheduledDateTime, routeItems } = response.data
          ?.data as ApiTransactionRoutes;

        const currentPendingRoutesItems = routeItems.filter((routeItem) =>
          isRouteStopOrderPending(routeItem.routeItemStateTypeId),
        );

        setCurrentScheduledDateTime(scheduledDateTime);
        setTotalPendingRoutesItems(currentPendingRoutesItems.length);
      },
      withPostFailure: () => {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      },
    },
    [],
  );

  const [data, setData] =
    useState<Nullable<DialogSetFeebakStopContextData>>(null);

  const [, loadingItemData] = useRequest({
    request: getItemById,
    payload: itemId as string,
    initialLoading: hasItemId,
    isValidToRequest: hasItemId,
    withPostSuccess: (response) => {
      const {
        title,
        cashOnDeliveryAmount,
        itemTasks,
        itemCollects,
        itemTypeId,
        collectCompanyId,
        itemId: currentItemId,
      } = response.data?.data as ItemCreateExtended;

      const isCollect =
        itemTypeId === ItemTypes.Charge && Boolean(collectCompanyId);

      setData({
        title,
        isCollect,
        arrivalDate: null,
        deliveredDate: null,
        cashOnDeliveryAmount,
        amountPaid: null,
        docsFiles: [],
        evidencesFiles: [],
        receptorName: null,
        receptorDocument: null,
        rejectedReason: null,
        itemTasks,
        itemCollects,
        notes: null,
        isWarehouse: false,
        currentItemId: currentItemId,
      });
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const [, loadingWarehouseData] = useRequest({
    request: getWarehouseInfo,
    payload: warehouseId as string,
    initialLoading: hasWarehouseId,
    isValidToRequest: hasWarehouseId,
    withPostSuccess: (response) => {
      const { title } = response.data?.data as WarehouseInfo;

      setData({
        title,
        isCollect: false,
        arrivalDate: null,
        deliveredDate: null,
        cashOnDeliveryAmount: null,
        amountPaid: null,
        docsFiles: [],
        evidencesFiles: [],
        receptorName: null,
        receptorDocument: null,
        rejectedReason: null,
        itemTasks: [],
        itemCollects: [],
        notes: null,
        isWarehouse: true,
        currentItemId: null,
      });
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const getCurrentStepByFeedback = useCallback(
    (routeItemStateTypeId: RouteItemStateType) => {
      let step: DialogSetFeebakStopContextStepsId = 'selection';

      if (routeItemStateTypeId === RouteItemStateType.FinalizedSuccess)
        step = 'completed';

      if (routeItemStateTypeId === RouteItemStateType.FinalizedError)
        step = 'incompleted';

      return step;
    },
    [],
  );

  const [, loadingFeedback] = useRequest({
    request: getFeedbackRouteItem,
    payload: routeItemId,
    initialLoading: isModify,
    isValidToRequest: isModify,
    withPostSuccess: (response) => {
      const {
        routeItem,
        arrivalTime,
        deliveredTime,
        amountPaid,
        routeItemFeedbackDocs,
        routeItemFeedbackFiles,
        receptorName,
        receptorId,
        rejectedReason,
        notes,
      } = response.data?.data as RouteItemFeedback;

      const step = getCurrentStepByFeedback(routeItem.routeItemStateTypeId);

      let title = '';
      let isCollect = false;
      let itemTasks: ItemTask[] = [];
      let itemCollects: ItemCollect[] = [];
      let isWarehouse = false;
      let currentItemId: Nullable<string> = null;

      if (routeItem.item) {
        title = routeItem.item.title;

        isCollect =
          routeItem.item.itemTypeId === ItemTypes.Charge &&
          Boolean(routeItem.item.collectCompanyId);

        itemTasks = routeItem.item.itemTasks;

        itemCollects = routeItem.item.itemCollects;

        currentItemId = routeItem.item.itemId;
      }

      if (routeItem.warehouse) {
        title = routeItem.warehouse.title;

        isWarehouse = true;
      }

      setCurrentStep(step);

      setData({
        title,
        isCollect,
        arrivalDate: arrivalTime ?? deliveredTime,
        deliveredDate: deliveredTime,
        cashOnDeliveryAmount: null,
        amountPaid,
        docsFiles: routeItemFeedbackDocs,
        evidencesFiles: routeItemFeedbackFiles,
        receptorName,
        receptorDocument: receptorId,
        rejectedReason,
        itemTasks,
        itemCollects,
        notes,
        isWarehouse,
        currentItemId,
      });
    },
  });

  const { handleUpdateRouteState } = useUpdateRouteState({
    showSnackBar: false,
  });

  const handleSuccessSetRoutItemFeedback = useCallback(() => {
    if (!isModify && totalPendingRoutesItems <= 1) {
      handleUpdateRouteState({
        routeId,
        routeStateTypeId: RouteStateTypes.Finalized,
        rejectedNote: null,
      });
    }

    onClose();
  }, [
    handleUpdateRouteState,
    isModify,
    onClose,
    routeId,
    totalPendingRoutesItems,
  ]);

  const { loading: loadingSetFeeback, handleSetRoutItemFeedback } =
    useSetRoutItemFeedback({
      onSuccess: handleSuccessSetRoutItemFeedback,
    });

  const loading = useMemo(
    () =>
      loadingPreferences ||
      loadingRoute ||
      loadingItemData ||
      loadingWarehouseData ||
      loadingFeedback,
    [
      loadingPreferences,
      loadingRoute,
      loadingItemData,
      loadingWarehouseData,
      loadingFeedback,
    ],
  );

  return (
    <DialogSetFeebakStopContext.Provider
      value={{
        ...restProps,
        currentStep: { step: currentStep, setCurrentStep },
        data,
        rejectedReasons,
        currentScheduledDateTime,
        totalPendingRoutesItems,
        loading,
        isModify,
        setItemFeedbackRequest: {
          loading: loadingSetFeeback,
          handleSetFeedback: handleSetRoutItemFeedback,
        },
      }}
    >
      {children}
    </DialogSetFeebakStopContext.Provider>
  );
};

export default DialogSetFeebakStopProvider;
