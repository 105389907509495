import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { updateRouteState } from '~services/route';
import { RouteUpdateStateBody } from '~services/route/types';
import { ApiTransactionRoutes } from '~services/apiTransaction/types';

import { useLazyRequest } from './useLazyRequest';

export interface UseUpdateRouteStateParams {
  showSnackBar?: boolean;
  onSuccess?: (currentRoute: ApiTransactionRoutes) => void;
  onError?: () => void;
}

export interface UseUpdateRouteStateReturn {
  loading: boolean;
  handleUpdateRouteState: (data: RouteUpdateStateBody) => void;
}

export const useUpdateRouteState = (
  options?: UseUpdateRouteStateParams,
): UseUpdateRouteStateReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loading, , executeRequest] = useLazyRequest({
    request: updateRouteState,
    withPostSuccess: (response) => {
      const currentRoute = response.data?.data as ApiTransactionRoutes;

      options?.onSuccess?.(currentRoute);

      if (options?.showSnackBar) {
        enqueueSnackbar('El estado de la ruta fue modificado correctamente', {
          variant: 'success',
        });
      }
    },
    withPostFailure: () => {
      options?.onError?.();

      if (options?.showSnackBar) {
        enqueueSnackbar(
          'No se pudo modificar el estado de la ruta, intente nuevamente',
          { variant: 'error' },
        );
      }
    },
  });

  const handleUpdateRouteState = useCallback(
    async (data: RouteUpdateStateBody) => {
      await executeRequest(data);
    },
    [executeRequest],
  );

  return { loading, handleUpdateRouteState };
};
