import { ReactElement, useMemo } from 'react';
import { Stack, TextField, Divider, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import moment from 'moment';

import { hasError } from '~utils/formHelpers';
import { useUtcOffset } from '~hooks/index';

import { useDialogFeedbackStopContext } from '../../../../DialogSetFeedbackStopContext';
import DialogSetFeedBackStopFileUpload from '../../../../DialogSetFeedBackStopFileUpload';
import { FIELDS_NAME, getInitialValues } from '../../utils';

const DialogSetFeedbackStopCompletedEvidence = (): ReactElement => {
  const { data, currentScheduledDateTime } = useDialogFeedbackStopContext();

  const formatterDateUtcOffset = useUtcOffset();

  const {
    values,
    touched,
    errors,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const arrivalMinDate = useMemo(() => {
    if (currentScheduledDateTime) {
      return new Date(
        formatterDateUtcOffset(
          currentScheduledDateTime,
          moment.HTML5_FMT.DATETIME_LOCAL,
        ),
      );
    }

    return undefined;
  }, [currentScheduledDateTime, formatterDateUtcOffset]);

  return (
    <Stack spacing={2} divider={<Divider />}>
      <DateTimePicker
        label="Fecha"
        value={values[FIELDS_NAME.ARRIVAL_DATE]}
        onChange={(dateValue) =>
          setFieldValue(FIELDS_NAME.ARRIVAL_DATE, dateValue)
        }
        minDateTime={arrivalMinDate}
        renderInput={(props) => (
          <TextField
            {...props}
            fullWidth
            onBlur={() => setFieldTouched(FIELDS_NAME.ARRIVAL_DATE, true)}
            error={hasError(touched, errors, FIELDS_NAME.ARRIVAL_DATE)}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      {!!data?.cashOnDeliveryAmount && (
        <TextField
          label="Monto cobrado (Opcional)"
          fullWidth
          autoComplete="off"
          type="number"
          {...getFieldProps(FIELDS_NAME.AMOUNT_PAID)}
          helperText={`Recuerde que el monto a cobrar es ${data.cashOnDeliveryAmount}`}
          InputLabelProps={{ shrink: true }}
        />
      )}

      <DialogSetFeedBackStopFileUpload
        name={FIELDS_NAME.DOCS_FILES}
        label="Documentación (Opcional)"
        labelUplaodButton="Cargar documentación"
        emptyMessage="No hay documentos para mostrar"
      />

      <DialogSetFeedBackStopFileUpload
        name={FIELDS_NAME.EVIDENCE_FILES}
        label="Evidencia de visita (Opcional)"
        labelUplaodButton="Cargar evidencia"
        emptyMessage="No hay imagenes para mostrar"
      />

      <Stack spacing={1}>
        <Typography fontWeight="bold">Receptor</Typography>

        <TextField
          label="Nombre de quien recibe (Opcional)"
          fullWidth
          {...getFieldProps(FIELDS_NAME.RECEPTOR_NAME)}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Documento de quien recibe (Opcional)"
          fullWidth
          {...getFieldProps(FIELDS_NAME.RECEPTOR_DOC)}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>

      <TextField
        label="Notas (Opcional)"
        fullWidth
        multiline
        rows={3}
        {...getFieldProps(FIELDS_NAME.NOTES)}
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );
};

export default DialogSetFeedbackStopCompletedEvidence;
