import { ReactElement, useMemo } from 'react';
import { Tab } from '@mui/material';
import { Link as RouterLink, useLocation, Outlet } from 'react-router-dom';
import { some } from 'lodash';

import { PATHS } from '~constants/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import {
  AuthContainer,
  AuthContentContainer,
  AuthLogo,
  AuthTabs,
  AuthContent,
  AuthBackgroundImg,
} from './styles';

const TABS_LIST = [
  {
    path: PATHS.AUTH.LOGIN,
    label: 'Iniciar sesión',
  },
  {
    path: PATHS.AUTH.REGISTER,
    label: 'Crear cuenta',
  },
];

const AuthLayout = (): ReactElement => {
  const { pathname } = useLocation();

  const enabledTabs = useMemo(
    () => some(TABS_LIST, { path: pathname }),
    [pathname],
  );

  return (
    <>
      <AuthContainer>
        <AuthContentContainer>
          <AuthLogo src={toAbsoluteUrl('/logo.svg')} alt="Routix Logo" />

          {enabledTabs && (
            <AuthTabs value={pathname}>
              {TABS_LIST.map(({ label, path }) => (
                <Tab
                  key={`auth-tab-${path}`}
                  label={label}
                  value={path}
                  to={path}
                  component={RouterLink}
                />
              ))}
            </AuthTabs>
          )}

          <AuthContent>
            <Outlet />
          </AuthContent>
        </AuthContentContainer>

        <AuthBackgroundImg
          src={toAbsoluteUrl('/images/bg_auth.png')}
          alt="Background Auth"
        />
      </AuthContainer>
    </>
  );
};

export default AuthLayout;
