import { styled, AppBar, Tab } from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogSetFeedbackStopCompletedAppBar = styled(AppBar)({
  backgroundColor: '#ebebeb',
});

export const DialogSetFeedbackStopCompletedTab = styled(Tab)(({ theme }) => ({
  color: '#585d61',
  minWidth: '50%',
  borderRight: `1px solid ${theme.palette.common.white}`,
}));

export const DialogSetFeedbackStopCompletedTabPanel = styled(TabPanel)(
  ({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
  }),
);
