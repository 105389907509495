import * as Yup from 'yup';

import { Nullable, FileObj } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';

import { DialogSetFeebakStopContextData } from '../../DialogSetFeedbackStopContext';

export const FIELDS_NAME = {
  ARRIVAL_DATE: 'arrivalDate',
  REJECTED_REASON: 'rejectedReason',
  EVIDENCE_FILES: 'evidencesFiles',
  NOTES: 'notes',
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (
  data: Nullable<DialogSetFeebakStopContextData>,
) => ({
  [FIELDS_NAME.ARRIVAL_DATE]: data?.arrivalDate
    ? new Date(data.arrivalDate)
    : null,
  [FIELDS_NAME.REJECTED_REASON]: data?.rejectedReason ?? '',
  [FIELDS_NAME.EVIDENCE_FILES]: [] as FileObj[],
  [FIELDS_NAME.NOTES]: data?.notes ?? '',
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ARRIVAL_DATE]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.REJECTED_REASON]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.EVIDENCE_FILES]: Yup.array().of(Yup.mixed()),
  [FIELDS_NAME.NOTES]: Yup.string().notRequired(),
});
