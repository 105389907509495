import { ReactElement, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { FileUpload } from '~components/index';
import GalleryLightBox from '~components/GalleryLightBox';
import { GalleryLightBoxImg } from '~components/GalleryLightBox/types';
import { FileObj } from '~globals/types';

import { useDialogFeedbackStopContext } from './DialogSetFeedbackStopContext';

import { MAX_WIDTH } from './styles';

interface DialogSetFeedBackStopFileUploadProps {
  name: 'docsFiles' | 'evidencesFiles';
  label: string;
  labelUplaodButton: string;
  emptyMessage: string;
}

const DialogSetFeedBackStopFileUpload = ({
  name,
  label,
  labelUplaodButton,
  emptyMessage,
}: DialogSetFeedBackStopFileUploadProps): ReactElement => {
  const { data } = useDialogFeedbackStopContext();

  const { values } = useFormikContext<Record<string, FileObj[]>>();

  const imagesData = useMemo(() => {
    const dataRequest =
      data?.[name].map(
        (itemFile): GalleryLightBoxImg => ({
          id: itemFile.fileId,
          url: itemFile.file.url,
          alt: itemFile.file.description,
        }),
      ) ?? [];

    const dataForm = values[name].map(
      (previewFile): GalleryLightBoxImg => ({
        id: previewFile.id,
        url: URL.createObjectURL(previewFile.file),
        alt: previewFile.file.name,
      }),
    );

    return [...dataRequest, ...dataForm];
  }, [name, values, data]);

  return (
    <Stack spacing={1}>
      <Typography fontWeight="bold">{label}</Typography>

      {imagesData.length === 0 && (
        <Typography variant="body2" color="text.secondary">
          {emptyMessage}
        </Typography>
      )}

      {imagesData.length > 0 && (
        <GalleryLightBox images={imagesData} maxWidth={MAX_WIDTH - 6} />
      )}

      <FileUpload
        name={name}
        accept="image/*"
        label={labelUplaodButton}
        multiple
        fullWidth={false}
        variant="contained"
        enabledChipsInfo={false}
      />
    </Stack>
  );
};

export default DialogSetFeedBackStopFileUpload;
