import * as Yup from 'yup';

import { Nullable, FileObj } from '~globals/types';
import {
  ItemTaskStatusType,
  ItemCollectStatusTypes,
} from '~globals/types/enums';
import { VALIDATIONS_ERROR } from '~constants/index';
import { ItemTask, ItemCollect } from '~services/item/types';

import { DialogSetFeebakStopContextData } from '../../DialogSetFeedbackStopContext';

export const FIELDS_NAME = {
  ARRIVAL_DATE: 'arrivalDate',
  AMOUNT_PAID: 'amountPaid',
  DOCS_FILES: 'docsFiles',
  EVIDENCE_FILES: 'evidencesFiles',
  RECEPTOR_NAME: 'receptorName',
  RECEPTOR_DOC: 'receptorDocument',
  NOTES: 'notes',
  ITEM_TASKS: 'itemTasks',
  ITEM_COLLECTS: 'itemCollects',
} as const;

export const ITEM_TASKS_FIELDS_NAME = {
  ITEM_TASK_ID: 'itemTaskId',
  ITEM_ID: 'itemId',
  DESCRIPTION: 'description',
  NOTES: 'notes',
  ITEM_TASK_STATUS_TYPE_ID: 'itemTaskStatusTypeId',
  IS_OWNER: 'isOwner',
} as const;

export const ITEM_COLLECTS_FIELDS_NAME = {
  ITEM_COLLECT_ID: 'itemCollectId',
  ITEM_ID: 'itemId',
  COMPANY_ID: 'companyId',
  USER_ID: 'userId',
  DESCRIPTION: 'description',
  NOTES: 'notes',
  PARTENR_ITEM_ID: 'partnerItemId',
  REFERENCE_ID: 'referenceId',
  TRACKING_NUMBER: 'trackingNumber',
  ITEM_COLLECT_STATUS_TYPE_ID: 'itemCollectStatusTypeId',
  ITEM_COLLECT_STATUS_TYPE: 'itemCollectStatusType',
  CREATION_DATE_TIME: 'creationDateTime',
  DELETED: 'deleted',
  IS_OWNER: 'isOwner',
} as const;

const initializeItemTasks = (itemTasks: ItemTask[], isCollect: boolean) => {
  if (isCollect) return [];

  return itemTasks.map((itemTask) => ({
    [ITEM_TASKS_FIELDS_NAME.ITEM_TASK_ID]: itemTask.itemTaskId,
    [ITEM_TASKS_FIELDS_NAME.ITEM_ID]: itemTask.itemId,
    [ITEM_TASKS_FIELDS_NAME.DESCRIPTION]: itemTask.description,
    [ITEM_TASKS_FIELDS_NAME.NOTES]: itemTask.notes,
    [ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID]:
      itemTask.itemTaskStatusTypeId === ItemTaskStatusType.Pending
        ? ItemTaskStatusType.Success
        : itemTask.itemTaskStatusTypeId,
    [ITEM_TASKS_FIELDS_NAME.IS_OWNER]: false,
  }));
};

const initializeItemCollects = (
  itemCollects: ItemCollect[],
  isCollect: boolean,
) => {
  if (!isCollect) return [];

  return itemCollects.map((itemCollect) => ({
    [ITEM_COLLECTS_FIELDS_NAME.ITEM_COLLECT_ID]: itemCollect.itemCollectId,
    [ITEM_COLLECTS_FIELDS_NAME.ITEM_ID]: itemCollect.itemId,
    [ITEM_COLLECTS_FIELDS_NAME.COMPANY_ID]: itemCollect.companyId,
    [ITEM_COLLECTS_FIELDS_NAME.USER_ID]: itemCollect.userId,
    [ITEM_COLLECTS_FIELDS_NAME.DESCRIPTION]: itemCollect.description,
    [ITEM_COLLECTS_FIELDS_NAME.NOTES]: itemCollect.notes,
    [ITEM_COLLECTS_FIELDS_NAME.PARTENR_ITEM_ID]: itemCollect.partnerItemId,
    [ITEM_COLLECTS_FIELDS_NAME.REFERENCE_ID]: itemCollect.referenceId,
    [ITEM_COLLECTS_FIELDS_NAME.TRACKING_NUMBER]: itemCollect.trackingNumber,
    [ITEM_COLLECTS_FIELDS_NAME.ITEM_COLLECT_STATUS_TYPE_ID]:
      itemCollect.itemCollectStatusTypeId === ItemCollectStatusTypes.Pending
        ? ItemCollectStatusTypes.NotCollected
        : itemCollect.itemCollectStatusTypeId,
    [ITEM_COLLECTS_FIELDS_NAME.ITEM_COLLECT_STATUS_TYPE]:
      itemCollect.itemCollectStatusType,
    [ITEM_COLLECTS_FIELDS_NAME.CREATION_DATE_TIME]:
      itemCollect.creationDateTime,
    [ITEM_COLLECTS_FIELDS_NAME.DELETED]: itemCollect.deleted,
    [ITEM_COLLECTS_FIELDS_NAME.IS_OWNER]: false,
  }));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (
  data: Nullable<DialogSetFeebakStopContextData>,
) => ({
  [FIELDS_NAME.ARRIVAL_DATE]: data?.arrivalDate
    ? new Date(data.arrivalDate)
    : null,
  [FIELDS_NAME.AMOUNT_PAID]: data?.amountPaid ?? '',
  [FIELDS_NAME.DOCS_FILES]: [] as FileObj[],
  [FIELDS_NAME.EVIDENCE_FILES]: [] as FileObj[],
  [FIELDS_NAME.RECEPTOR_NAME]: data?.receptorName ?? '',
  [FIELDS_NAME.RECEPTOR_DOC]: data?.receptorDocument ?? '',
  [FIELDS_NAME.NOTES]: data?.notes ?? '',
  [FIELDS_NAME.ITEM_TASKS]: initializeItemTasks(
    data?.itemTasks ?? [],
    data?.isCollect ?? false,
  ),
  [FIELDS_NAME.ITEM_COLLECTS]: initializeItemCollects(
    data?.itemCollects ?? [],
    data?.isCollect ?? false,
  ),
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ARRIVAL_DATE]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.AMOUNT_PAID]: Yup.number().notRequired(),
  [FIELDS_NAME.DOCS_FILES]: Yup.array().of(Yup.mixed()),
  [FIELDS_NAME.EVIDENCE_FILES]: Yup.array().of(Yup.mixed()),
  [FIELDS_NAME.RECEPTOR_NAME]: Yup.string().notRequired(),
  [FIELDS_NAME.RECEPTOR_DOC]: Yup.string().notRequired(),
  [FIELDS_NAME.NOTES]: Yup.string().notRequired(),
  [FIELDS_NAME.ITEM_TASKS]: Yup.array().of(
    Yup.object().shape({
      [ITEM_TASKS_FIELDS_NAME.ITEM_TASK_ID]: Yup.string().notRequired(),
      [ITEM_TASKS_FIELDS_NAME.DESCRIPTION]: Yup.string().required(
        VALIDATIONS_ERROR.REQUIRED,
      ),
      [ITEM_TASKS_FIELDS_NAME.NOTES]: Yup.string().when(
        ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID,
        {
          is: (itemTaskStatusTypeIdValue: ItemTaskStatusType) =>
            itemTaskStatusTypeIdValue === ItemTaskStatusType.Error,
          then: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
          otherwise: Yup.string().nullable().notRequired(),
        },
      ),
      [ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID]: Yup.number(),
      [ITEM_TASKS_FIELDS_NAME.IS_OWNER]: Yup.boolean(),
    }),
  ),
  [FIELDS_NAME.ITEM_COLLECTS]: Yup.array().of(
    Yup.object().shape({
      [ITEM_COLLECTS_FIELDS_NAME.ITEM_COLLECT_ID]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.ITEM_ID]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.COMPANY_ID]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.USER_ID]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.DESCRIPTION]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.NOTES]: Yup.string().notRequired().nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.PARTENR_ITEM_ID]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.REFERENCE_ID]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.TRACKING_NUMBER]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.ITEM_COLLECT_STATUS_TYPE_ID]: Yup.number()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.ITEM_COLLECT_STATUS_TYPE]: Yup.mixed()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.CREATION_DATE_TIME]: Yup.string()
        .notRequired()
        .nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.DELETED]: Yup.boolean().nullable(),
      [ITEM_COLLECTS_FIELDS_NAME.IS_OWNER]: Yup.boolean(),
    }),
  ),
});
