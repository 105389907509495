import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/types';

import {
  AdminReportSummaryFilter,
  AdminReportSummary,
  AdminReportDownloadRoutes,
  AdminReportDownloadRouteItems,
  AdminReportDownloadItemTasks,
  AdminReportUserSatisfactionFilters,
  AdminReportUserSatisfaction,
  AdminReportHeatmapCoords,
} from './types';

export const ADMIN_REPORTS_ENPOINTS = {
  getReportSummary: '/api/v1/Admin/Report/ReportSummary',
  getReportRoutes: '/api/v1/Admin/Report/Routes',
  getReportRouteItems: '/api/v1/Admin/Report/RouteItems',
  getReportItemTasks: '/api/v1/Admin/Report/ItemTasks',
  getReportUserSatisfaction: '/api/v1/Track/RouteItemSourvey/ReportSourvey',
  getReportFeedbackReviews: '/api/v1/Track/RouteItemSourvey/ReportSourveyCSV',
  getReportHeatmap: '/api/v1/Admin/Report/Heatmap',
};

export const getReportSummary = (
  body: AdminReportSummaryFilter,
): ApiPromise<DataResponse<AdminReportSummary>, ErrorResponse> =>
  API.post<DataResponse<AdminReportSummary>, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportSummary,
    body,
  );

export const getReportRoutes = (
  filters: AdminReportDownloadRoutes,
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportRoutes,
    filters,
    { responseType: 'blob', timeout: 120000 },
  );

export const getReportRouteItems = (
  filters: AdminReportDownloadRouteItems,
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportRouteItems,
    filters,
    { responseType: 'blob', timeout: 120000 },
  );

export const getReportItemTasks = (
  filters: AdminReportDownloadItemTasks,
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportItemTasks,
    filters,
    { responseType: 'blob', timeout: 120000 },
  );

export const getReportUserSatisfaction = (
  filters: AdminReportUserSatisfactionFilters,
): ApiPromise<DataResponse<AdminReportUserSatisfaction>, ErrorResponse> =>
  API.post<DataResponse<AdminReportUserSatisfaction>, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportUserSatisfaction,
    filters,
  );

export const getReportFeedbackReviews = (
  filters: AdminReportUserSatisfactionFilters,
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportFeedbackReviews,
    filters,
    { responseType: 'blob', timeout: 120000 },
  );

export const getReportHeatmap = (): ApiPromise<
  DataResponse<AdminReportHeatmapCoords[]>,
  ErrorResponse
> =>
  API.get<DataResponse<AdminReportHeatmapCoords[]>, ErrorResponse>(
    ADMIN_REPORTS_ENPOINTS.getReportHeatmap,
  );
