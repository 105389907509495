import { ReactElement, ReactNode, useMemo } from 'react';
import { Typography, Chip, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Nullable } from '~globals/types';

import { useDialogFeedbackStopContext } from './DialogSetFeedbackStopContext';
import {
  DialogSetFeedbackStopContentHeader,
  DialogSetFeedbackStopContentContainer,
  DialogSetFeedbackStopActions,
} from './styles';

interface DialogSetFeedbackStopContentProps {
  onSubmit?: () => void;
  loadingSubmit?: boolean;
  disabledSubmit?: boolean;
  children: ReactNode;
}

interface DialogSetFeedbackStopContentChipInfo {
  type: 'success' | 'error';
  description: string;
}

const DialogSetFeedbackStopContent = ({
  onSubmit,
  loadingSubmit,
  disabledSubmit,
  children,
}: DialogSetFeedbackStopContentProps): ReactElement => {
  const {
    onClose,
    data,
    isModify,
    currentStep: { step: activeStep },
  } = useDialogFeedbackStopContext();

  const title = useMemo(() => {
    if (isModify) {
      return `Modificar evidencia ${data?.title}`;
    }

    return `Finalizar parada ${data?.title}`;
  }, [data, isModify]);

  const badgeTitleInfo = useMemo(() => {
    let info: Nullable<DialogSetFeedbackStopContentChipInfo> = null;

    if (activeStep === 'completed') {
      info = { type: 'success', description: 'Visita completa' };
    }

    if (activeStep === 'incompleted') {
      info = { type: 'error', description: 'Visita incompleta' };
    }

    return info;
  }, [activeStep]);

  return (
    <>
      <DialogSetFeedbackStopContentHeader>
        <Typography component="h2" variant="h6">
          {title}
        </Typography>

        {badgeTitleInfo && (
          <Chip
            variant="filled"
            color={badgeTitleInfo.type}
            label={badgeTitleInfo.description}
            size="small"
            sx={{ color: 'common.white', textTransform: 'uppercase' }}
          />
        )}
      </DialogSetFeedbackStopContentHeader>

      <DialogSetFeedbackStopContentContainer dividers>
        {children}
      </DialogSetFeedbackStopContentContainer>

      <DialogSetFeedbackStopActions>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          Cerrar
        </Button>

        {onSubmit && (
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadingSubmit}
            onClick={onSubmit}
            disabled={disabledSubmit}
          >
            Guardar
          </LoadingButton>
        )}
      </DialogSetFeedbackStopActions>
    </>
  );
};

export default DialogSetFeedbackStopContent;
