import { ReactElement, ElementType } from 'react';
import { CircularProgress } from '@mui/material';
import { TabContext } from '@mui/lab';

import { DialogSetFeedbackStopProps } from './types';
import DialogSetFeebakStopProvider, {
  useDialogFeedbackStopContext,
  DialogSetFeebakStopContextStepsId,
} from './DialogSetFeedbackStopContext';
import {
  DialogSetFeedbackStopSelection,
  DialogSetFeedbackStopCompleted,
  DialogSetFeedbackStopIncompleted,
} from './Steps';
import {
  DialogSetFeedbackStopContainer,
  DialogSetFeedbackStopLoadingContainer,
  DialogSetFeedbackStopStepPanel,
} from './styles';

interface DialogAssignCarrierCompanyStepPanelItem {
  id: DialogSetFeebakStopContextStepsId;
  panel: ElementType;
}

const STEPS_PANELS: DialogAssignCarrierCompanyStepPanelItem[] = [
  {
    id: 'selection',
    panel: DialogSetFeedbackStopSelection,
  },
  {
    id: 'completed',
    panel: DialogSetFeedbackStopCompleted,
  },
  {
    id: 'incompleted',
    panel: DialogSetFeedbackStopIncompleted,
  },
];

const DialogSetFeedbackStop = (): ReactElement => {
  const {
    open,
    currentStep: { step: activeStep },
    loading,
  } = useDialogFeedbackStopContext();

  return (
    <DialogSetFeedbackStopContainer open={open} maxWidth={false}>
      {loading && (
        <DialogSetFeedbackStopLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogSetFeedbackStopLoadingContainer>
      )}

      {!loading && (
        <TabContext value={activeStep}>
          {STEPS_PANELS.map(({ id, panel: Panel }) => (
            <DialogSetFeedbackStopStepPanel
              key={`dialog-set-feedback-stop-step-panel-${id}`}
              value={id}
            >
              <Panel />
            </DialogSetFeedbackStopStepPanel>
          ))}
        </TabContext>
      )}
    </DialogSetFeedbackStopContainer>
  );
};

const WrapperDialogSetFeedbackStop = (
  props: DialogSetFeedbackStopProps,
): ReactElement => (
  <DialogSetFeebakStopProvider {...props}>
    <DialogSetFeedbackStop />
  </DialogSetFeebakStopProvider>
);

export default WrapperDialogSetFeedbackStop;
