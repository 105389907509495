import { ReactElement } from 'react';
import { Stack, DialogContentText, Button } from '@mui/material';

import DialogSetFeedbackStopContent from '../../DialogSetFeedbackStopContent';
import { useDialogFeedbackStopContext } from '../../DialogSetFeedbackStopContext';

const DialogSetFeedbackStopSelection = (): ReactElement => {
  const {
    currentStep: { setCurrentStep },
  } = useDialogFeedbackStopContext();

  return (
    <DialogSetFeedbackStopContent>
      <Stack spacing={2} flex={1} justifyContent="center" alignItems="center">
        <DialogContentText variant="h6">
          ¿Qué tal fue la visita?
        </DialogContentText>

        <Stack spacing={2}>
          <Button
            color="success"
            variant="contained"
            onClick={() => setCurrentStep('completed')}
          >
            Visita completa
          </Button>

          <Button
            color="error"
            variant="contained"
            onClick={() => setCurrentStep('incompleted')}
          >
            No se pudo realizar
          </Button>
        </Stack>
      </Stack>
    </DialogSetFeedbackStopContent>
  );
};

export default DialogSetFeedbackStopSelection;
