import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { TabPanel } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MAX_WIDTH = 600;

export const DialogSetFeedbackStopContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    minHeight: 600,
  },
});

export const DialogSetFeedbackStopLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogSetFeedbackStopContentContainer = styled(DialogContent)({
  display: 'flex',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: 475,
});

export const DialogSetFeedbackStopStepPanel = styled(TabPanel)({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  flex: 1,
  height: '100%',
});

export const DialogSetFeedbackStopActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogSetFeebackStopResetStepIconButton = styled(Button)(
  ({ theme }) => ({
    minWidth: 26,
    height: 26,
    padding: 0,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
  }),
);

export const DialogSetFeebackStopResetStepIconButtonIcon = styled(
  FontAwesomeIcon,
)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
}));

export const DialogSetFeedbackStopContentHeader = styled('div')(
  ({ theme }) => ({
    flex: 'none',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);
