import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { InfoMercadoPagoCard, InfoMercadoPagoCardImg } from './styles';

const SubscriptionInfoMercadoPagoCard = (): ReactElement => (
  <InfoMercadoPagoCard>
    <Stack spacing={2}>
      <Typography variant="body1" color="text.secondary">
        Si estás en <b>Argentina</b>, puedes pagar en pesos argentinos
        utilizando tu tarjeta de crédito a través de <b>Mercado Pago</b>.
      </Typography>

      <Typography variant="body1" color="text.secondary">
        Ademas, ofrecemos <b>facturación tipo A o B</b> según tu condición
        tributaria.
      </Typography>
    </Stack>

    <InfoMercadoPagoCardImg
      src={toAbsoluteUrl('/images/mp_logo_blue.png')}
      alt="Mercado Pago Logo"
    />
  </InfoMercadoPagoCard>
);

export default SubscriptionInfoMercadoPagoCard;
