import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Stack } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import moment from 'moment';
import { omit } from 'lodash';

import { Nullable } from '~globals/types';
import { RouteItemStateType } from '~globals/types/enums';
import { getItemValue, formatterDate } from '~utils/index';
import { RouteItemFeedbackSetItemTasks } from '~services/route/types';
import { ItemCollect } from '~services/item/types';

import { useDialogFeedbackStopContext } from '../../DialogSetFeedbackStopContext';
import DialogSetFeedbackStopContent from '../../DialogSetFeedbackStopContent';
import DialogSetFeebackStopResetStep from '../../DialogSetFeebackStopResetStep';

import {
  FIELDS_NAME,
  ITEM_TASKS_FIELDS_NAME,
  ITEM_COLLECTS_FIELDS_NAME,
  getInitialValues,
  validationSchema,
} from './utils';
import {
  DialogSetFeedbackStopCompletedEvidence,
  DialogSetFeedbackStopCompletedItemTasks,
  DialogSetFeedbackStopCompletedItemCollects,
} from './Tabs';
import {
  DialogSetFeedbackStopCompletedAppBar,
  DialogSetFeedbackStopCompletedTab,
  DialogSetFeedbackStopCompletedTabPanel,
} from './styles';

const DialogSetFeedbackStopCompleted = (): ReactElement => {
  const {
    routeItemId,
    data,
    isModify,
    setItemFeedbackRequest: { loading: loadingSetFeedback, handleSetFeedback },
  } = useDialogFeedbackStopContext();

  const isCollect = useMemo(() => data?.isCollect ?? false, [data]);

  const isWarehouse = useMemo(() => data?.isWarehouse ?? false, [data]);

  const tabsList = useMemo(
    () => [
      {
        id: 'evidence',
        label: 'Evidencia',
        enabled: true,
      },
      {
        id: 'itemTasks',
        label: 'Articulo / Tareas',
        enabled: !isCollect,
      },
      {
        id: 'itemCollects',
        label: 'Pedidos',
        enabled: isCollect,
      },
    ],
    [isCollect],
  );

  const tabsListPanels = useMemo(
    () => [
      {
        id: tabsList[0].id,
        panel: DialogSetFeedbackStopCompletedEvidence,
        enabled: tabsList[0].enabled,
      },
      {
        id: tabsList[1].id,
        panel: DialogSetFeedbackStopCompletedItemTasks,
        enabled: tabsList[1].enabled,
      },
      {
        id: tabsList[2].id,
        panel: DialogSetFeedbackStopCompletedItemCollects,
        enabled: tabsList[2].enabled,
      },
    ],
    [tabsList],
  );

  const [activeTab, setActiveTab] = useState(tabsList[0].id);

  const handleChangeActiveTab = useCallback(
    (_e: SyntheticEvent, newValue: string) => {
      setActiveTab(newValue);
    },
    [],
  );

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      const routeItemStateTypeId = RouteItemStateType.FinalizedSuccess;

      const arrivalDate = formatterDate(values[FIELDS_NAME.ARRIVAL_DATE], {
        format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
      });

      const deliveredDate = data?.deliveredDate
        ? formatterDate(data.deliveredDate, {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          })
        : arrivalDate;

      const amountPaid = getItemValue(
        values,
        FIELDS_NAME.AMOUNT_PAID,
        (value) => Number(value),
      ) as Nullable<number>;

      const itemTasks = getItemValue(
        values,
        FIELDS_NAME.ITEM_TASKS,
        (tasks): RouteItemFeedbackSetItemTasks[] =>
          tasks.map((task) => omit(task, [ITEM_TASKS_FIELDS_NAME.IS_OWNER])),
        [] as RouteItemFeedbackSetItemTasks[],
      );

      const itemCollects = getItemValue(
        values,
        FIELDS_NAME.ITEM_COLLECTS,
        (collects): ItemCollect[] =>
          collects.map((collect) =>
            omit(collect, [ITEM_COLLECTS_FIELDS_NAME.IS_OWNER]),
          ),
        [] as ItemCollect[],
      );

      await handleSetFeedback({
        routeItemId,
        routeItemStateTypeId,
        arrivalDate,
        deliveredDate,
        amountPaid,
        docsFiles: values[FIELDS_NAME.DOCS_FILES],
        evidencesFiles: values[FIELDS_NAME.EVIDENCE_FILES],
        receptorName: getItemValue(values, FIELDS_NAME.RECEPTOR_NAME),
        receptorDocument: getItemValue(values, FIELDS_NAME.RECEPTOR_DOC),
        notes: getItemValue(values, FIELDS_NAME.NOTES),
        rejectedReason: null,
        itemTasks,
        itemCollects,
        isCollect,
        isModify,
      });

      setSubmitting(false);
    },
    [data, handleSetFeedback, routeItemId, isCollect, isModify],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit,
  });

  const { submitForm, isSubmitting, dirty } = formikBag;

  return (
    <DialogSetFeedbackStopContent
      onSubmit={submitForm}
      loadingSubmit={loadingSetFeedback || isSubmitting}
      disabledSubmit={isModify && !dirty}
    >
      <FormikProvider value={formikBag}>
        <Stack spacing={2} flex={1} height="100%">
          <DialogSetFeebackStopResetStep />

          <TabContext value={activeTab}>
            {!isWarehouse && (
              <DialogSetFeedbackStopCompletedAppBar
                position="static"
                color="tertiary"
                elevation={0}
              >
                <TabList
                  onChange={handleChangeActiveTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {tabsList.map(
                    ({ label, id, enabled }) =>
                      enabled && (
                        <DialogSetFeedbackStopCompletedTab
                          key={`dialog-set-feedback-stop-completed-tab-${id}`}
                          label={label}
                          value={id}
                        />
                      ),
                  )}
                </TabList>
              </DialogSetFeedbackStopCompletedAppBar>
            )}

            {tabsListPanels.map(
              ({ id, panel: Panel, enabled }) =>
                enabled && (
                  <DialogSetFeedbackStopCompletedTabPanel
                    key={`dialog-set-feedback-stop-completed-tab-panel-${id}`}
                    value={id}
                  >
                    <Panel />
                  </DialogSetFeedbackStopCompletedTabPanel>
                ),
            )}
          </TabContext>
        </Stack>
      </FormikProvider>
    </DialogSetFeedbackStopContent>
  );
};

export default DialogSetFeedbackStopCompleted;
