import {
  ReactElement,
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Stack,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Divider,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import { get } from 'lodash';

import { hasError } from '~utils/index';
import { DEFAULT_UUIID_EMPTY } from '~constants/commons';
import { ItemTaskStatusType } from '~globals/types/enums';

import { useDialogFeedbackStopContext } from '../../../../DialogSetFeedbackStopContext';
import {
  FIELDS_NAME,
  ITEM_TASKS_FIELDS_NAME,
  getInitialValues,
} from '../../utils';
import { Nullable } from '~globals/types';

const NAME_FIELD = FIELDS_NAME.ITEM_TASKS;

const DialogSetFeedbackStopCompletedItemTasks = (): ReactElement => {
  const { data } = useDialogFeedbackStopContext();

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const toPathField = useCallback(
    (index: number, keyField: string) => `${NAME_FIELD}[${index}][${keyField}]`,
    [],
  );

  const itemTasksList = useMemo(() => values[NAME_FIELD], [values]);

  const [newItemTaskDesc, setNewItemTaskDesc] = useState('');

  const handleChangeNewItemTaskDesc = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target ?? event.currentTarget;

      setNewItemTaskDesc(value);
    },
    [],
  );

  const handleChangeIsCompleted = useCallback(
    (event: ChangeEvent<HTMLInputElement>, index: number) => {
      const { checked } = event.target ?? event.currentTarget;

      const value = checked
        ? ItemTaskStatusType.Success
        : ItemTaskStatusType.Error;

      setFieldValue(
        toPathField(index, ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID),
        value,
      );
    },
    [setFieldValue, toPathField],
  );

  const handleBlurIsCompleted = useCallback(
    (index: number) => {
      setFieldTouched(
        toPathField(index, ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID),
        true,
      );
    },
    [setFieldTouched, toPathField],
  );

  const handleAddItemTaskInList = useCallback(
    (insertHelper: ArrayHelpers['insert']) => {
      if (newItemTaskDesc && data?.currentItemId) {
        const newArticle = {
          [ITEM_TASKS_FIELDS_NAME.ITEM_TASK_ID]: DEFAULT_UUIID_EMPTY,
          [ITEM_TASKS_FIELDS_NAME.ITEM_ID]: data.currentItemId,
          [ITEM_TASKS_FIELDS_NAME.DESCRIPTION]: newItemTaskDesc,
          [ITEM_TASKS_FIELDS_NAME.NOTES]: null,
          [ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID]:
            ItemTaskStatusType.Success,
          [ITEM_TASKS_FIELDS_NAME.IS_OWNER]: true,
        };

        insertHelper(0, newArticle);

        setNewItemTaskDesc('');
      }
    },
    [newItemTaskDesc, data],
  );

  const getValueForIndex = useCallback(
    <R,>(index: number, fielName: string) =>
      get(values, toPathField(index, fielName)) as R,
    [toPathField, values],
  );

  const getDescriptionValueForIndex = useCallback(
    (index: number) =>
      getValueForIndex<string>(index, ITEM_TASKS_FIELDS_NAME.DESCRIPTION) ?? '',
    [getValueForIndex],
  );

  const getNoteValueForIndex = useCallback(
    (index: number) =>
      getValueForIndex<Nullable<string>>(index, ITEM_TASKS_FIELDS_NAME.NOTES) ??
      '',
    [getValueForIndex],
  );

  const getCompletedValueForIndex = useCallback(
    (index: number) => {
      const itemCollectStatusTypeId = getValueForIndex<ItemTaskStatusType>(
        index,
        ITEM_TASKS_FIELDS_NAME.ITEM_TASK_STATUS_TYPE_ID,
      );

      return itemCollectStatusTypeId === ItemTaskStatusType.Success;
    },
    [getValueForIndex],
  );

  return (
    <FieldArray name={NAME_FIELD}>
      {({ insert, remove }) => (
        <Stack spacing={3} divider={<Divider />}>
          <Stack spacing={1} direction="row" alignItems="center">
            <TextField
              label="Nuevo artículo / tarea"
              InputLabelProps={{ shrink: true }}
              size="small"
              value={newItemTaskDesc}
              onChange={handleChangeNewItemTaskDesc}
              autoComplete="off"
              fullWidth
            />

            <Tooltip title="Agregar artículo / tarea" arrow placement="right">
              <span>
                <IconButton
                  aria-label="Add item task"
                  onClick={() => handleAddItemTaskInList(insert)}
                  color="primary"
                  size="small"
                  disabled={!newItemTaskDesc}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>

          {!itemTasksList.length && (
            <Typography variant="h6" fontWeight="bold" textAlign="center">
              No hay artículos / tareas cargados
            </Typography>
          )}

          {!!itemTasksList.length && (
            <Stack spacing={2} divider={<Divider />}>
              {itemTasksList.map((_itemTask, itemTaskIndex) => (
                <Stack
                  key={toPathField(itemTaskIndex, 'root')}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <Stack spacing={1} width="100%">
                    <TextField
                      label="Descripción"
                      value={getDescriptionValueForIndex(itemTaskIndex)}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      disabled
                      autoComplete="off"
                      fullWidth
                    />

                    <TextField
                      label="Comentarios"
                      {...getFieldProps(
                        toPathField(
                          itemTaskIndex,
                          ITEM_TASKS_FIELDS_NAME.NOTES,
                        ),
                      )}
                      value={getNoteValueForIndex(itemTaskIndex)}
                      error={hasError(
                        touched,
                        errors,
                        toPathField(
                          itemTaskIndex,
                          ITEM_TASKS_FIELDS_NAME.NOTES,
                        ),
                      )}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      autoComplete="off"
                      fullWidth
                    />
                  </Stack>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={getCompletedValueForIndex(itemTaskIndex)}
                        onChange={(event) =>
                          handleChangeIsCompleted(event, itemTaskIndex)
                        }
                        onBlur={() => handleBlurIsCompleted(itemTaskIndex)}
                      />
                    }
                    label="Completo"
                  />

                  {getValueForIndex<boolean>(
                    itemTaskIndex,
                    ITEM_TASKS_FIELDS_NAME.IS_OWNER,
                  ) && (
                    <Tooltip
                      title="Eliminar artículo / tarea"
                      arrow
                      placement="right"
                    >
                      <span>
                        <IconButton
                          aria-label="Delete item task"
                          onClick={() => remove(itemTaskIndex)}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      )}
    </FieldArray>
  );
};

export default DialogSetFeedbackStopCompletedItemTasks;
