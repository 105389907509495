import { ReactElement } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { faChevronLeft as GoBackIcon } from '@fortawesome/free-solid-svg-icons';

import { useDialogFeedbackStopContext } from './DialogSetFeedbackStopContext';
import {
  DialogSetFeebackStopResetStepIconButton,
  DialogSetFeebackStopResetStepIconButtonIcon,
} from './styles';

const DialogSetFeebackStopResetStep = (): ReactElement => {
  const {
    currentStep: { setCurrentStep },
  } = useDialogFeedbackStopContext();

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Tooltip title="Ir atrás" arrow>
        <DialogSetFeebackStopResetStepIconButton
          color="inherit"
          variant="contained"
          onClick={() => setCurrentStep('selection')}
        >
          <DialogSetFeebackStopResetStepIconButtonIcon icon={GoBackIcon} />
        </DialogSetFeebackStopResetStepIconButton>
      </Tooltip>

      <Typography
        variant="body2"
        component="h2"
        color="text.primary"
        fontWeight="bold"
      >
        ¿Deseas cambiar el estado de la visita?
      </Typography>
    </Stack>
  );
};

export default DialogSetFeebackStopResetStep;
