import { styled, Card } from '@mui/material';

export const InfoMercadoPagoCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const InfoMercadoPagoCardImg = styled('img')({
  width: 220,
});
